import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";

const Options = ( props ) => {

  const {
    type,
    item,
    itemConfig,
    linkedItem,
    linkedItemConfig,
    setSelectedItem,
    setChangeNoticeOpen,
    setLinkedItem,
    changeItem,
    checked
  } = props;

  const maybeChangeItem = ( value ) => {
    const checkAnswers = checked.map( ( check ) => !!check.answer );

    if ( checkAnswers.includes( true ) ) {
      setChangeNoticeOpen( true );
      setSelectedItem( value );
    } else {
      changeItem( value );
    }
  };

  const changeLinkedItem = ( value ) => {
    setLinkedItem( value );
  };

  const hasItem = Boolean( item );

  return (
    <div className="selectors">
      <FormControl sx={ { m: 1, minWidth: 80 } } className="select-control-item">
        <InputLabel id="select-item-label" className="select-item-label">
          { itemConfig.nameSingular }
        </InputLabel>
        <Select
          labelId="select-item-label"
          label={ itemConfig.nameSingular }
          value={ item }
          className="select-item"
          onChange={ ( e ) => maybeChangeItem( e.target.value ) }
          autoWidth
        >
          { Object.keys( itemConfig.items ).map( function( key ) {
            return (
              <MenuItem key={ key } value={ key }>
                { itemConfig.items[ key ] }
              </MenuItem>
            );
          } ) }
        </Select>
      </FormControl>
      { hasItem && linkedItemConfig && (
        <>
          <ArrowForwardIosIcon />
          <FormControl
            sx={ { m: 1, minWidth: 80 } }
            className="select-control-linked-item"
          >
            <InputLabel
              id="select-label-linked-item"
              className="select-label-linked-item"
            >
              { linkedItemConfig.nameSingular }
            </InputLabel>
            <Select
              labelId="select-label-linked-item"
              label="linked-item"
              value={ linkedItem }
              className="select-item"
              onChange={ ( e ) => changeLinkedItem( e.target.value ) }
              autoWidth
            >
              { Object.keys( linkedItemConfig.items ).map( ( key ) => {
                return (
                  <MenuItem key={ key } value={ linkedItemConfig.items[ key ] }>
                    { linkedItemConfig.items[ key ] }
                  </MenuItem>
                );
              } ) }
            </Select>
          </FormControl>
        </>
      ) }
    </div>
  );
};

export default Options;
