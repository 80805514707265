import Button from "@mui/material/Button";
import DateRangeForm from "./Export/DateRangeForm";

const Settings = ( { business, settings } ) => {
  const { items } = settings;

  const clearAllData = () => {
    console.log( ["BUSINESS", business] );

    clearChecks();
    clearLatestChecks();
  };

  const clearChecks = () => {
    const path = `/businesses/${ business.id }/checks`;
    emptyCollection( path );
  };

  const clearLatestChecks = () => {
    const path = `/businesses/${ business.id }/latest-checks`;
    emptyCollection( path );
  };

  const emptyCollection = ( path ) => {
    const ref = firestore.collection( path );
    ref.onSnapshot( ( snapshot ) => {
      snapshot.docs.forEach( ( doc ) => {
        ref.doc( doc.id ).delete();
      } );
    } );
  };

  return (
    <div className="Settings">
      <DateRangeForm submitText="Export Checks" onSubmit={ ( start, end ) => console.log( [start, end] ) } />
      <div style={ { display: "flex", flexDirection: "column", alignItems: "flex-start" } }>
        <h2>Dangerous Tools</h2>
        <Button variant="contained" onClick={ clearLatestChecks } style={ { marginBottom: "1rem" } }>
          Clear Latest Checks
        </Button>
        <Button variant="contained" onClick={ clearAllData }>
          Clear All Data
        </Button>
      </div>
      { Object.keys( items ).map( ( index ) => {
        const item = items[ index ];
        return (
          <div key={ index }>
            <h2>
              { item.nameSingular } / { item.namePlural }
            </h2>
            <ul style={ { columns: 2 } }>
              { Object.keys( item.items ).map( ( itemsKey ) => {
                return (
                  <li key={ itemsKey }>
                    { item.items[ itemsKey ] } (<code>{ itemsKey }</code>)
                  </li>
                );
              } ) }
            </ul>
            <h3>Checks</h3>
            <ul>
              { Object.keys( item.checks ).map( ( checksKey ) => {
                return <li key={ checksKey }>{ item.checks[ checksKey ] }</li>;
              } ) }
            </ul>
          </div>
        );
      } ) }
    </div>
  );
};

export default Settings;
