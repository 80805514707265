import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./firebase_config";

const userSignIn = () => {
  signInWithPopup( auth, new GoogleAuthProvider );
};

function SignIn() {
  return (
    <nav className="navbar">
      <div className="navbar--top">
        <div className="navbar--left">
        </div>
        <div className="navbar--right">
          <button className="sign-in" onClick={ userSignIn }>Sign in with Google</button>
        </div>
      </div>
    </nav>
  );
}

function Main() {
  const [user] = useAuthState( auth );
  return user ? <App /> : <SignIn />;
}

ReactDOM.render( <Main />, document.getElementById( "root" ) );
