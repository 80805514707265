import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const ChangeNotice = ( { open, onClose, title, message } ) => {

  return (
    <Dialog
      open={ open }
      onClose={ () => onClose( false ) }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        { title }
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          { message }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ () => onClose( false ) }>Cancel</Button>
        <Button onClick={ () => onClose( true ) } autoFocus>Continue</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeNotice;