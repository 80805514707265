import { getFirestore, enableIndexedDbPersistence } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCpm8U22I08pTXesVehHpxAdnaog1UlrjI",
  authDomain: "wpgr-checklist.firebaseapp.com",
  projectId: "wpgr-checklist",
  storageBucket: "wpgr-checklist.appspot.com",
  messagingSenderId: "110179147504",
  appId: "1:110179147504:web:5fd99b06fe1cbf58ebec35",
  measurementId: "G-686SHLWVTG"
};

const firebaseApp = initializeApp( firebaseConfig );
const db = getFirestore( firebaseApp );

enableIndexedDbPersistence( db )
  .catch( ( err ) => {
    if ( err.code === "failed-precondition" ) {
      // Multiple tabs open, persistence can only be enabled in one tab at a time.
    } else if ( err.code === "unimplemented" ) {
      // The current browser does not support all features required to enable persistence.
    }
  } );

export const auth = getAuth( firebaseApp );
export default db;
