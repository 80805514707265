import React from "react";
import { Button } from "@mui/material";
import { collection, getDocs, query, where } from "firebase/firestore";
import db from "../../firebase_config";

const ExportChecks = ( { bid, type } ) => {

  const handleClick = async () => {

    /**
     * Sort an object by its keys.
     * @param object
     * @returns {Object}
     */
    const sortObjectByKeys = ( object ) => {
      return Object.keys( object ).sort().reduce( ( r, k ) => ( r[ k ] = object[ k ], r ), {} );
    };

    /**
     * Convert object to string.
     *
     * @param object
     * @returns {string}
     */
    const convertObject = ( object ) => {
      const row = [];
      for ( const [key, value] of Object.entries( object ) ) {
        row.push( `${ key }:${ value }` );
      }

      return row.join( "|" );
    };

    /**
     * Convert array containing objects to string.
     *
     * @param array
     * @returns {string}
     */
    const convertArray = ( array ) => {
      const arr = array.map( ( value ) => {
        return convertObject( value );
      } );

      return arr.join( "|" );
    };

    // Set up query to get all checks for specified type.
    const q = query(
      collection( db, `businesses/${ bid }/checks` ),
      where( "item", "==", type )
    );

    // Query check data.
    await getDocs( q ).then( ( snapshot ) => {
      let checks = [];

      snapshot.forEach( ( doc ) => {
        checks.push( {
          id: doc.id,
          ...doc.data()
        } );
      } );

      if ( checks.length ) {

        // Determine CSV column headers. May not be accurate based on first item.
        const columns = Object.keys( checks[ 0 ] ).sort().join( "," );
        // CSV "headers".
        let csvContent = `data:text/csv;charset=utf-8,${ columns }\n`;

        checks.forEach( ( check ) => {
          // Ensure checks are always sorted the same.
          check = sortObjectByKeys( check );

          const row = [];
          // Iterate object with key/value.
          for ( const [checkKey, checkVal] of Object.entries( check ) ) {
            switch ( checkKey ) {
              case "business":
                row.push( convertObject( checkVal ) );
                break;
              case "results":
                row.push( convertArray( checkVal ) );
                break;
              default:
                row.push( checkVal );
            }
          }

          csvContent += `${ row }\n`;
        } );

        // Download data to CSV.
        const encodedUri = encodeURI( csvContent );
        window.open( encodedUri );
      }
    } );
  };

  return (
    <Button variant="contained" onClick={ handleClick }>Export All Checks</Button>
  );
};

export default ExportChecks;