import React, { useState, useEffect } from "react";
import Nav from "./Components/Nav";
import Checks from "./Components/Checks";
import Settings from "./Components/Settings";
import Dashboard from "./Components/Dashboard";
import Item from "./Components/Item";
import Tools from "./Components/Tools";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import db from "./firebase_config";
import config from "./config";

import { collection, query, where, getDocs } from "firebase/firestore";
import Archive from "./Components/Archive";

const App = () => {
  const [business, setBusiness] = useState( {} );
  const [type, setType] = useState( config.defaults.items );

  useEffect( async () => {

    const q = query(
      collection( db, "businesses" ),
      where( "bid", "==", config.id ) );

    await getDocs( q ).then( ( snapshot ) => {
      let business = {};

      snapshot.forEach( ( doc ) => {
          business = {
            "id": doc.id,
            "bid": doc.data().bid,
            "name": doc.data().name
          };
        }
      );

      if ( Object.keys( business ).length ) {
        setBusiness( business );
      }
    } );
  }, [] );

  return (
    <BrowserRouter>
      <div className="container">
        <Nav type={ type } onChangeType={ setType } config={ config } />
        <Routes>
          <Route path="/" element={ <Dashboard business={ business } settings={ config } type={ type } /> } />
          <Route path="/checks" element={ <Checks business={ business } settings={ config } type={ type } /> } />
          <Route path="/settings" element={ <Settings business={ business } settings={ config } /> } />
          <Route path="/tools" element={ <Tools business={ business } config={ config } type={ type } /> } />
          <Route path="/archive" element={ <Archive type={ type } /> } />
          <Route path="/item/:slug" element={ <Item business={ business } type={ type } /> } />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
