import React, { useState, useEffect } from "react";
import db from "../../firebase_config";
import { collection, doc, setDoc } from "firebase/firestore";
import {
  FormGroup,
  Button,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import ChangeNotice from "./ChangeNotice";
import SubmitNote from "./SubmitNote";
import Options from "./Options";

const Checks = ( { business, settings, type } ) => {

  const itemConfig = settings.items[ type ];

  // Organize checks into iterable format.
  const compileChecks = () => {
    const checks = [];
    itemConfig.checks.forEach( ( check, index ) => {
      checks[ index ] = {
        question: check,
        answer: false
      };
    } );

    return checks;
  };

  const [item, setItem] = useState( "" );
  const [selectedItem, setSelectedItem] = useState( "" );
  const [checked, setChecked] = useState( compileChecks );
  const [changeNoticeOpen, setChangeNoticeOpen] = useState( false );
  const [noteOpen, setNoteOpen] = useState( false );
  const [note, setNote] = useState( "" );
  const [linkedItem, setLinkedItem] = useState( "" );
  // Linked item config.
  const linkedItemConfig = settings.items[ itemConfig.linkedItem ];
  // Does item have additional linked item?
  const hasLinkedItem = Boolean( itemConfig.linkedItem );

  /**
   * Reset state when the type is changed.
   */
  useEffect( () => {
    resetState();
  }, [type] );

  /**
   * Close popup note.
   *
   * @param proceed should action proceed to submit?
   */
  const handleNoteClose = ( proceed ) => {
    setNoteOpen( false );

    if ( proceed ) {
      handleSubmit();
    }
  };

  /**
   * Handle exit notice close.
   *
   * @param proceed whether form should proceed submitting.
   */
  const handleNoticeClose = ( proceed ) => {
    setChangeNoticeOpen( false );
    if ( proceed ) {
      changeItem( selectedItem );
    }
  };

  const changeItem = ( value ) => {
    setChecked( compileChecks );
    setItem( value );
  };

  const onChangeCheckbox = ( value, index ) => {
    let checks = checked;
    checks[ index ].answer = value;

    setChecked( [...checks] );
  };

  // Compile data and save it to collections.
  const handleSubmit = () => {
    const data = {
      note,
      results: checked,
      timestamp: new Date().getTime(),
      slug: item,
      item: type,
      name: itemConfig.items[ item ],
      linkedItem,
      business
    };

    saveCheck( data );
    saveLatestCheck( data );
  };

  // Save checks to primary check storage.
  const saveCheck = async ( data ) => {

    const newCheckRef = doc( collection( db, `businesses/${ business.id }/checks` ) );
    await setDoc( newCheckRef, data )
      .then( () => {
        // resetState();
      } )
      .catch( ( error ) => {
        console.error( "Error adding document to checks: ", error );
      } );
  };

  // Save checks to latest-checks collection for reporting.
  const saveLatestCheck = async ( data ) => {

    const newLatestRef = doc( db, `businesses/${ business.id }/latest-checks`, `${ type }-${ item }` );
    await setDoc( newLatestRef, data )
      .then( () => {
        resetState();
      } )
      .catch( ( error ) => {
        console.error( "Error adding document to latest checks: ", error );
      } );
  };

  // Put everything back to its starting state.
  const resetState = () => {
    setItem( "" );
    setChecked( compileChecks );
    setNote( "" );
    setLinkedItem( "" );
  };

  if ( hasLinkedItem && !linkedItem ) {
    return (
      <div className="checks">
        <h1>{ itemConfig.nameSingular } Check</h1>
        <div className="form">
          <Options
            type={ type }
            item={ item }
            items={ settings.items }
            itemConfig={ itemConfig }
            linkedItem={ linkedItem }
            linkedItemConfig={ linkedItemConfig }
            setSelectedItem={ setSelectedItem }
            setChangeNoticeOpen={ setChangeNoticeOpen }
            setLinkedItem={ setLinkedItem }
            changeItem={ changeItem }
            resetState={ resetState }
            checked={ checked }
          />
        </div>
      </div>
    );
  }

  return (
    <div className="checks">
      <h1>{ itemConfig.nameSingular } Check { item && `(${ itemConfig.items[ item ] })` }</h1>
      <ChangeNotice
        open={ changeNoticeOpen }
        title="You have not submitted your check"
        message="If you proceed without clicking submit, your check will be lost."
        onClose={ handleNoticeClose }
      />
      <div className="form">
        <Options
          type={ type }
          item={ item }
          items={ settings.items }
          itemConfig={ itemConfig }
          linkedItem={ linkedItem }
          linkedItemConfig={ linkedItemConfig }
          setSelectedItem={ setSelectedItem }
          setChangeNoticeOpen={ setChangeNoticeOpen }
          setLinkedItem={ setLinkedItem }
          changeItem={ changeItem }
          resetState={ resetState }
          checked={ checked }
        />
        { item && !!checked.length && (
          <FormGroup className="check-items-group">
            { checked.map( ( check, index ) => {
              return (
                <FormControlLabel
                  key={ `b-${ index }` }
                  label={ check.question }
                  className="check-items-label"
                  control={
                    <Checkbox
                      checked={ !!check.answer }
                      onChange={
                        ( e ) => onChangeCheckbox( e.target.checked, index )
                      }
                      inputProps={ { "aria-label": "controlled" } }
                      sx={ { "& .MuiSvgIcon-root": { fontSize: 42 } } }
                    />
                  }
                />
              );
            } ) }
          </FormGroup>
        ) }
        { item && !!checked.length && (
          <Button
            onClick={ () => setNoteOpen( true ) }
            className="submit"
            variant="contained"
            style={ { margin: "1rem", alignSelf: "center", width: "200px" } }
          >
            Submit
          </Button>
        ) }
        <SubmitNote
          open={ noteOpen }
          onClose={ handleNoteClose }
          note={ note }
          handleNote={ ( value ) => setNote( value ) }
        />
      </div>
    </div>
  );
};

export default Checks;
