import React, { useState, useEffect } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import renderCellExpand from "./renderCellExpand";
import db from "../firebase_config";

const Dashboard = ( { business, settings, type } ) => {
  const [dataRows, setDataRows] = useState( [] );
  const [dataColumns, setDataColumns] = useState( [] );

  useEffect( async () => {
    const q = query(
      collection( db, `businesses/${ business.id }/latest-checks` ),
      where( "item", "==", type )
    );

    await getDocs( q ).then( ( snapshot ) => {
      let checks = [];

      snapshot.forEach( ( doc ) => {
        checks.push( {
          id: doc.id,
          ...doc.data()
        } );
      } );

      setDataColumns( compileColumns() );
      setDataRows( compileRows( checks ) );
    } );
  }, [type, business.id] );

  const passesChecks = ( results ) => {
    const length = results.length;
    let count = 0;

    if ( 0 === length ) {
      return false;
    }

    results.forEach( ( result ) => {
      if ( !!result.answer ) {
        count ++;
      }
    } );

    return length === count;
  };

  const formatTime = ( timestamp ) => {
    const date = new Date( timestamp );

    return date.toLocaleString( "en-US" );
  };

  const compileColumns = () => {
    let columns = [
      {
        field: "id",
        headerName: "ID",
        editable: false,
        flex: 0.5
      },
      {
        field: "name",
        headerName: "Name",
        editable: false,
        flex: 0.125,
        renderCell: ( params ) => {
          const url = `/item/${ params.value.slug }`;
          return (
            <a href={ url }>{ params.value.name }</a>
          );
        }
      },
      /*{
        field: "status",
        headerName: "Status",
        editable: false,
        flex: 0.125,
        align: "center",
        valueFormatter: ( params ) =>
          passesChecks( params.value ) ? "PASS" : "FAIL"
      },*/
      {
        field: "date",
        headerName: "Date",
        type: "number",
        editable: false,
        flex: 0.25,
        valueFormatter: ( params ) => formatTime( params.value )
      },
      {
        field: "note",
        headerName: "Note",
        description: "This column has a value getter and is not sortable.",
        sortable: false,
        flex: 0.25,
        renderCell: renderCellExpand
      }
    ];

    if ( settings.items[ type ].linkedItem ) {
      columns.push( {
        field: "linkedItem",
        headerName:
        settings.items[ settings.items[ type ].linkedItem ].nameSingular,
        flex: 0.125,
        align: "center"
      } );
    }

    return columns;
  };

  const compileRows = ( checks ) => {
    let rows = [];

    checks.forEach( ( check ) => {

      let row = {
        id: check.id,
        name: check,
        status: check.results,
        date: check.timestamp,
        note: check.note
      };

      if ( settings.items[ type ].linkedItem ) {
        row.linkedItem = check.linkedItem;
      }

      rows.push( row );
    } );

    return rows;
  };
  return (
    <div className="dashboard">
      { !!dataRows.length && (
        <DataGrid
          columnVisibilityModel={ {
            id: false
          } }
          rows={ dataRows }
          columns={ dataColumns }
          pageSize={ 50 }
          autoHeight
          rowsPerPageOptions={ [50] }
          initialState={ {
            sorting: {
              sortModel: [{ field: "date", sort: "desc" }]
            }
          } }
          getRowClassName={ ( params ) =>
            passesChecks( params.row.status ) ? "pass" : "fail"
          }
        />
      ) }
    </div>
  );
};

export default Dashboard;
