import ExportChecks from "./Export/ExportChecks";
import React from "react";
import DateRangeForm from "./Export/DateRangeForm";

const Tools = ( { business, type } ) => {

  const onExportRange = ( start, end ) => {

  };

  return (
    <div className="tools">
      <h1>Tools</h1>
      <ExportChecks bid={ business.id } type={ type } />
      <br />
      <br />
      <DateRangeForm submitText="Export Checks" onSubmit={ ( start, end ) => onExportRange( start, end ) } />
    </div>
  );
};

export default Tools;
