import React from "react";
import { auth } from "../firebase_config";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardIcon from "@mui/icons-material/Dashboard";

import {
  FormControl,
  MenuItem,
  Select,
  Typography
} from "@mui/material";


const Nav = ( { type, onChangeType, config } ) => {
  const item = config.items[ type ];

  const SignOut = () => {
    auth.signOut().then( ( r ) => {
      console.log( "Sign Out", r );
    } );
  };

  return (
    <nav className="navbar">
      <div className="navbar--top">
        <div className="navbar--left">
          <ul>
            <li><Link to="/"><DashboardIcon /></Link></li>
            <li><Link to="/tools"><SettingsIcon /></Link></li>
          </ul>
        </div>
        <div className="navbar--right">
          <button className="sign-out" onClick={ SignOut }>
            Sign Out
          </button>
        </div>
      </div>
      <div className="navbar--bottom">
        <div className="navbar--left">
          <ul>
            <li><Link to="/checks"><Typography>Checks</Typography></Link></li>
            <li><Link to="/archive"><Typography>All { item.namePlural }</Typography></Link></li>
          </ul>
        </div>
        <div className="navbar--right">
          <FormControl
            className="select-control-type"
            size="small"
          >
            <Select
              value={ type }
              className="select-type"
              onChange={ ( e ) => onChangeType( e.target.value ) }
              autoWidth
            >
              { Object.keys( config.items ).map( function( key ) {
                return (
                  <MenuItem key={ key } value={ key }>
                    { config.items[ key ].namePlural }
                  </MenuItem>
                );
              } ) }
            </Select>
          </FormControl>
        </div>
      </div>
    </nav>
  )
    ;
};

export default Nav;