const config = {
  id: "western-pleasure",
  defaults: {
    items: "horses",
  },
  items: {
    horses: {
      namePlural: "Horses",
      nameSingular: "Horse",
      linkedItem: "saddles",
      items: {
        albert: "Albert",
        bc: "BC",
        chevy: "Chevy",
        chrome: "Chrome",
        doc: "Doc",
        dollar: "Dollar",
        dually: "Dually",
        dudley: "Dudley",
        dusty: "Dusty",
        fawn: "Fawn",
        flash: "Flash",
        fudsy: "Fudsy",
        gunner: "Gunner",
        gypsy: "Gypsy",
        halfPint: "Half Pint",
        hancock: "Hancock",
        hemi: "Hemi",
        kate: "Kate",
        kingGeorge: "King George",
        kt: "KT",
        lovebug: "Lovebug",
        lucky: "Lucky",
        magnum: "Magnum",
        major: "Major",
        maverick: "Maverick",
        missKity: "Miss Kity",
        moose: "Moose",
        nova: "Nova",
        nugget: "Nugget",
        pepper: "Pepper",
        rascal: "Rascal",
        rocket: "Rocket",
        rusty: "Rusty",
        smokey: "Smokey",
        sonny: "Sonny",
        spiderMan: "Spider Man",
        tango: "Tango",
        theHulk: "The Hulk",
        turbo: "Turbo",
        whiskey: "Whiskey",
        willow: "Willow",
        wonderWoman: "Wonder Woman",
        zulu: "Zulu",
      },
      checks: [
        "The bridle is in good working order and correctly fitted to the horse as per Dude Ranch Foundation horse safety manual (see page 8).",
        "The saddle and its accessories are in good working order and correctly fitted to the horse as per Dude Ranch Foundation horse safety manual (see page 8).",
        "The horse does not show any signs of pain or distress as per Dude Ranch Foundation horse safety manual (see page 16).",
        "The horse passes nose to tail routine check as per Dude Ranch Foundation horse safety manual (see page 17).",
      ],
    },
    teams: {
      namePlural: "Teams",
      nameSingular: "Team",
      linkedItem: "sleighs",
      items: {
        "jake-julie": "Jake & Julie",
        "doc-duke": "Doc & Duke",
        "major-fancy": "Major & Fancy",
        "bess-betty": "Bess & Betty",
      },
      checks: [
        "Harness is properly fitted to team.",
        "Tires/skis are in good working order.",
        "Frame is free of damage."
      ],
    },
    sleighs: {
      namePlural: "Sleighs",
      nameSingular: "Sleigh",
      items: {
        cutter: "Cutter",
        "3-seater": "3 Seater",
        ark: "The Ark",
        "08": "The 08",
      },
      checks: [
        "All bolts have been tightened.",
        "Welds have been visually inspected for cracks.",
        "Tires/skis are in good working order.",
        "Frame is free of damage."
      ],
    },
    bikes: {
      namePlural: "Bikes",
      nameSingular: "Bike",
      items: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
      checks: [
        "Both front and rear tires have been inflated to 15 PSI.",
        "Brake pads are in good condition.",
        "Brakes engage long before reaching handlebars.",
        "both crank bolts are tight.",
        "The handlebars are tight.",
        "The front and rear wheels are tight."
      ],
    },
    saddles: {
      namePlural: "Saddles",
      nameSingular: "Saddle",
      items: {
        1: "1",
        2: "2",
        3: "3",
        5: "5",
        6: "6",
        7: "7",
        8: "8",
        9: "9",
        10: "10",
        11: "11",
        12: "12",
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        19: "19",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
        25: "25",
        26: "26",
        27: "27",
        28: "28",
        31: "31",
        32: "32",
        33: "33",
        34: "34",
        35: "35",
        37: "37",
        39: "39",
        41: "41",
        42: "42",
        43: "43",
        44: "44",
        45: "45",
        50: "50",
      },
      checks: [
        "Tree has no detectable cracks.",
        "Leather has been inspected and is undamaged.",
        "Leather has been adequately oiled.",
        "All ties and fasteners are tight.",
        "Shearling is in good usable condition."
      ],
    },
    "e-bikes": {
      namePlural: "E-Bikes",
      nameSingular: "E-Bike",
      items: {
        0: "0",
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
      },
      checks: [
        "Both front and rear tires have been inflated to 15 PSI.",
        "Brake pads are in good condition.",
        "Brakes engage long before reaching handlebars.",
        "both crank bolts are tight.",
        "The handlebars are tight.",
        "The front and rear wheels are tight."
      ],
    },
  },
};

export default config;
