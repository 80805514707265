import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import db from "../firebase_config";
import { collection, query, where, getDocs } from "firebase/firestore";
import config from "../config";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Item = ( { business, type } ) => {
  const [records, setRecords] = useState( [] );
  const [loading, setLoading] = useState( true );
  const { slug } = useParams();

  const formatTime = ( timestamp ) => {
    const date = new Date( timestamp );

    return date.toLocaleString( "en-US" );
  };

  const passesChecks = ( results ) => {
    const length = results.length;
    let count = 0;

    if ( 0 === length ) {
      return false;
    }

    results.forEach( ( result ) => {
      if ( !!result.answer ) {
        count ++;
      }
    } );

    return length === count;
  };

  /**
   * Sort checks array DESC by timestamp.
   *
   * @param checks
   * @returns {*}
   */
  const sortChecksDesc = ( checks ) => {
    return checks.sort( ( a, b ) => {
      if ( b.timestamp < a.timestamp ) {
        return - 1;
      }
      if ( a.timestamp < b.timestamp ) {
        return 1;
      }
      // a must be equal to b
      return 0;
    } );
  };

  useEffect( async () => {
    if ( !Boolean( business.id ) ) {
      return;
    }

    const q = query(
      collection( db, `businesses/${ business.id }/checks` ),
      where( "item", "==", type ),
      where( "slug", "==", slug )
    );

    await getDocs( q )
      .then( ( snapshot ) => {
        const data = [];
        snapshot.forEach( ( doc ) => {
          data.push( {
            id: doc.id,
            ...doc.data()
          } );
        } );

        setLoading( false );
        setRecords( sortChecksDesc( data ) );
      } ).catch( ( error ) => {
        setLoading( false );
        console.log( "Error getting:", error );
      } );
  }, [business.id] );

  const hasRecords = Boolean( records.length );
  const itemName = config.items[ type ].items[ slug ];

  if ( hasRecords ) {

    const singleRecord = config.items[ type ].items[ slug ];

    return (
      <div className="item-page">
        <h1>{ itemName } ({ config.items[ type ].nameSingular })</h1>
        { records.map( ( record ) => {
          return (
            <div key={ record.id } className="item-page--record">
              <Accordion className="item-page--accordion">
                <AccordionSummary
                  expandIcon={ <ExpandMoreIcon /> }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={ "color-" + String( passesChecks( record.results ) ) }
                >
                  <p style={ { margin: "0" } }>{ formatTime( record.timestamp ) }</p>
                </AccordionSummary>
                <AccordionDetails>
                  { Boolean( record.note ) && (
                    <>
                      <h2>Note</h2>
                      <p>{ record.note }</p>
                    </>
                  ) }
                  { Boolean( record.results ) && (
                    <>
                      <h2>Results</h2>
                      { record.results.map( ( result, index ) => {
                        return (
                          <div key={ index } className={ "item-page--result color-" + result.answer }>
                            <p>{ result.question }</p>
                          </div>
                        );
                      } ) }
                    </>
                  ) }
                </AccordionDetails>
              </Accordion>
            </div>
          );
        } ) }
      </div>
    );

  } else if ( loading ) {
    return (
      <div className=" item-page">
        <h1>{ itemName } ({ config.items[ type ].nameSingular })</h1>
        <p>Loading...</p>
      </div>
    );
  } else {
    return (
      <div className=" item-page">
        <h1>{ itemName } ({ config.items[ type ].nameSingular })</h1>
        <p>There are currently no records available.</p>
      </div>
    );
  }
};

export default Item;