import React from "react";
import config from "../config";

const Archive = ( { type } ) => {
  const item = config.items[ type ];
  const items = item.items;

  return (
    <div className="item--archive">
      <h1>{ item.namePlural }</h1>
      <ul>
        { Object.keys( items ).map( ( key ) => {
          return (
            <li key={ key }>
              <a href={ `/item/${ key }` }>{ items[ key ] }</a>
            </li>
          );
        } ) }
      </ul>
    </div>
  );

};

export default Archive;