import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";

const SubmitNote = ( { open, onClose, note, handleNote } ) => {

  return (
    <Dialog
      open={ open }
      onClose={ () => onClose( false ) }
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Submission Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" className="submit-dialog-description">
          Would you like to take any notes before submitting this check?
        </DialogContentText>
        <TextField
          className="submit-dialog-note"
          placeholder="Add notes..."
          multiline
          minRows={ 6 }
          maxRows={ 12 }
          value={ note }
          onChange={ ( e ) => handleNote( e.target.value ) }
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={ () => onClose( false ) }>Cancel</Button>
        <Button onClick={ () => onClose( true ) } autoFocus>Submit</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitNote;