import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

const DateRangeForm = ( { submitText, onSubmit } ) => {
  const [dateStart, setDateStart] = useState( 0 );
  const [dateEnd, setDateEnd] = useState( 0 );

  const dateToUnix = ( date ) => {
    return new Date( date ).getTime() / 1000;
  };

  const getTodaysDate = () => {
    const today = new Date();

    const month = ( "0" + ( today.getMonth() + 1 ) ).slice( - 2 );
    const day = today.getUTCDate();
    const year = today.getUTCFullYear();

    return `${ year }-${ month }-${ day }`;
  };

  return (
    <>
      <TextField
        id="date"
        label="Start"
        type="date"
        defaultValue={ getTodaysDate() }
        sx={ { width: 220 } }
        InputLabelProps={ {
          shrink: true
        } }
        onChange={ ( e ) => setDateStart( e.target.value ) }
      />
      <TextField
        id="date"
        label="End"
        type="date"
        defaultValue={ getTodaysDate() }
        sx={ { width: 220 } }
        InputLabelProps={ {
          shrink: true
        } }
        onChange={ ( e ) => setDateEnd( e.target.value ) }
      />
      <Button variant="contained" onClick={ () => {
        onSubmit( dateStart, dateEnd );
      } }>
        { submitText }
      </Button>
    </>
  );
};

export default DateRangeForm;
